import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IFilterParams } from './index'
import type { ICategory, IYearCategory } from './types/category'
import type { ILinks, IMeta, ISeo } from './types/response'
import type { ITag } from './types/tag'
import type { IBrand, IOffice } from './types/office'
import type { IContentBlock } from './types/block'

export interface IProjectFilterParams extends IFilterParams {
  category_id: string
  year: string
  month: string
  tags: string[]
  type: string
}

export interface IProject {
  id: number
  title: string
  en_title: string
  subtitle: string
  categories: ICategory[]
  brands: IBrand[]
  design_offices: IOffice[]
  content: IContentBlock[]
  tags: ITag[]
  image: string
  seo: ISeo
  status: number
}

export interface IProjectList {
  data: IProject[]
  meta: IMeta
  links: ILinks
}

export interface IProjectIndexResultModel {
  top: IProject[]
  list: IProjectList
  categories: ICategory[]
  week_news: IProject[]
  year_month: IYearCategory[]
  popular_tags: ITag[]
}

export interface IProjectResultModel {
  info: IProject
  recommends: IProject[]
}

enum Api {
  projects = '/projects',
  project = '/projects/:id'
}

export function getProjects(params?: IProjectFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IProjectIndexResultModel>(Api.projects, params, option)
}

export function getProject(id: string, option?: HttpOption<any>) {
  return useHttp.get<IProjectResultModel>(Api.project.replace(':id', id), option)
}
