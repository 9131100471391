import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { ear } from '@fortawesome/free-regular-svg-icons'
// import {faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faEarthAmericas} from '@fortawesome/free-solid-svg-icons'
// import {
//   faHospital
// } from '@fortawesome/free-regular-svg-icons'

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

export default defineNuxtPlugin((nuxtApp) => {
  // 将图标添加到库中
  library.add(faEarthAmericas)
  // 全局注册 font-awesome-icon 组件
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon)
})