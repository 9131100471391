import { useUserStore } from '~/stores/user'

export default defineNuxtRouteMiddleware(to => {
  if (process.server) {
    // 404導向首頁
    if (!to.name) {
      return navigateTo(`https://www.iw-space.com.tw${to.fullPath}`, { redirectCode: 302, external: true })
    }
  }
})
