import type { ILoginParams, ILoginResultModel } from '~/api/auth'
import type { IProfileResultModel } from '~/api/user'

interface StoreState {
  tokenInfo: ILoginResultModel | undefined
  isLogin: boolean
  userProfile: IProfileResultModel
  sessionId: string
}

export const useUserStore = defineStore('userStore', {
  state: (): StoreState => ({
    tokenInfo: undefined,
    isLogin: false,
    userProfile: {} as IProfileResultModel,
    sessionId: ''
  }),
  actions: {
    async login(params: ILoginParams) {
      const { auth } = useApi()
      const { data, error } = await auth.login(params)
      if (error.value) {
        throw error.value
      }
      const tokenInfo = data.value?.token as ILoginResultModel
      this.tokenInfo = tokenInfo
      this.isLogin = true
      return tokenInfo
    },
    async logout() {
      const { auth } = useApi()
      await auth.logout()
      this.clearUserInfo()
    },
    clearUserInfo() {
      this.tokenInfo = undefined
      this.isLogin = false
    },
    setUserToken(token: ILoginResultModel) {
      this.tokenInfo = token
      this.isLogin = true
    },
    setProfile(profile: IProfileResultModel) {
      this.userProfile = profile
    },
    setSession(id: string) {
      this.sessionId = id
    }
  },
  getters: {
    token: state => state.tokenInfo?.token
  },
  persist: true
})
