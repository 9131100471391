<script setup lang="ts">
import { ElConfigProvider } from "element-plus";
import zhTw from "element-plus/dist/locale/zh-tw.mjs";

const locale = zhTw;
// console.log(zhTw);
</script>
<template>
  <NuxtLayout>
    <template #content>
      <el-config-provider :locale="locale">
        <NuxtPage />
      </el-config-provider>
    </template>
  </NuxtLayout>
  <SvgSymbol />
</template>
