import { useHttp, type HttpOption } from '../composables/useHttp'

export interface IAddCartParams {
  session_id: string
  type: 'SELECT' | 'ISSUE'
  item_id: number
  quantity: number
}

export interface ICartPayParams {
  session_id: string
  name: string
  phone: string
  email: string
  ship_type: string
  ship_channel: string
  ship_store: string
  country: string
  city: string
  town: string
  zip_code: string
  address: string
  invoice_type: string
  invoice_ubn: string
  carrier_type: string
  carrier_num: string
  pay_type: string
  memo: string
}

enum Api {
  getSelectCart = '/cart',
  addSelectCart = '/cart/add',
  deleteSelectCart = '/cart/clear',
  paySelectCart = '/cart/pay',
  getIssueCart = '/issue_cart',
  addIssueCart = '/issue_cart/add',
  deleteIssueCart = '/issue_cart/clear',
  payIssueCart = '/issue_cart/pay',
  updateShippingFee = '/cart_fee/:type'
}

export async function getSelectCart(params?: any, option?: HttpOption<any>) {
  return useHttp.get<any>(Api.getSelectCart, params, option)
}

export async function addSelectCart(params: IAddCartParams, option?: HttpOption<any>) {
  return useHttp.post<any>(Api.addSelectCart, params, option)
}

export async function paySelectCart(params: ICartPayParams, option?: HttpOption<any>) {
  return useHttp.post<any>(Api.paySelectCart, params, option)
}

export async function delSelectCart(params: { session_id: string; item_id: number }, option?: HttpOption<any>) {
  return useHttp.post<any>(Api.deleteSelectCart, params, option)
}

export async function getISsueCart(params?: any, option?: HttpOption<any>) {
  return useHttp.get<any>(Api.getIssueCart, params, option)
}

export async function addISsueCart(params: IAddCartParams, option?: HttpOption<any>) {
  return useHttp.post<any>(Api.addIssueCart, params, option)
}

export async function payIssueCart(params: ICartPayParams, option?: HttpOption<any>) {
  return useHttp.post<any>(Api.payIssueCart, params, option)
}

export async function delISsueCart(params: { session_id: string; item_id: number }, option?: HttpOption<any>) {
  return useHttp.post<any>(Api.deleteIssueCart, params, option)
}

export async function updateShippingFee(type: string, sessionId: string, option?: HttpOption<any>) {
  return useHttp.get<any>(Api.updateShippingFee.replace(':type', type), { session_id: sessionId }, option)
}
