import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IFilterParams } from './index'
import type { ILinks, IMeta, ISeo } from './types/response'

export interface ISearchItem {
  model_type: string
  model_id: number
  cat: string
  title: string
  en_title: string
  subtitle: string
  description: string
  price: number
  member_price: number
  image: string
  seo: ISeo
  created_at: string
  updated_at: string
}

export interface ISearchListResultModel {
  data: ISearchItem[]
  meta: IMeta
  links: ILinks
}

export interface ISearchTagListResultModel {
  data: any
}

enum Api {
  Search = '/search',
  SearchTag = '/search_tag'
}

export function getSearch(params?: IFilterParams, option?: HttpOption<any>) {
  return useHttp.get<ISearchListResultModel>(Api.Search, params, option)
}

export function getSearchTag(params?: IFilterParams, option?: HttpOption<any>) {
  return useHttp.get<ISearchTagListResultModel>(Api.SearchTag, params, option)
}
