import validate from "/www/node_modules/nuxt/dist/pages/runtime/validate.js";
import check_45session_45global from "/www/middleware/check-session.global.ts";
import redirect_45global from "/www/middleware/redirect.global.ts";
import manifest_45route_45rule from "/www/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  check_45session_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-designer": () => import("/www/middleware/auth-designer.ts"),
  "auth-regular": () => import("/www/middleware/auth-regular.ts"),
  auth: () => import("/www/middleware/auth.ts")
}