import type { IFilterParams } from '.'
import { useHttp, type HttpOption } from '../composables/useHttp'
import { ISeo } from './types/response'

export interface IOrderFilterParams extends IFilterParams {
  order_status: string
}

export interface IFavoriteFilterParams extends IFilterParams {
  type: string
}

export interface IViewLogFilterParams extends IFilterParams {
  type: string
}

export interface IProfileParams {
  role?: string
  first_name?: string
  last_name?: string
  phone_code?: string
  phone?: string
}

export interface IPasswordParams {
  password: string
}

export interface ISubmitPostParams {
  studio_name: string
  studio_url: string
  email: string
  name: string
  en_name: string
  status: string
  address: string
  year: string
  medias: File[]
  urls: string[]
  description: string
  memo: string
  google_recaptcha_token: string
}

export interface IProfileResultModel {
  id: number
  role: 'regular' | 'designer'
  first_name: string
  last_name: string
  name: string
  email: string
  phone_code: string
  phone: string
  google_id: string
  line_id: string
  facebook_id: string
  email_verified_at: string
  status: number
  is_subscribe: boolean
  is_subscribe_second: boolean
  is_subscribe_third: boolean
  studio: string
  studio_address: string
  studio_url: string
}

export interface IOrder {
  type: 'SELECTION' | 'ISSUE'
  id: number
  order_number: string
  buyer_name: string
  buyer_phone: string
  buyer_email: string
  buyer_city: string
  buyer_country: string
  buyer_town: string
  buyer_zipcode: string
  buyer_address: string
  ship_channel: string
  ship_store: string
  ship_type: string
  pay_tpe: string
  pay_status: string
  total: number
  status: number
  can_refund: boolean
  pay_url: string
  memo: number
  date: number
  subtotal: number
  delivery_fee: number
  items: IOrderItem[]
}

export interface IOrderItem {
  model_type: string
  model_id: number
  title: string
  image: string
  product_name: string
  product_spec: string
  price: string
  quantity: number
}

export interface IFavorite {
  type: string
  cat: string
  id: number
  title: string
  en_title: string
  image: string
  price: number
  member_price: number
  is_favorite: boolean
  seo: ISeo
}

export interface IViewlog {
  type: string
  cat: string
  id: number
  subtitle: string
  title: string
  en_title: string
  image: string
  price: string
  member_price: string
  deadline: string
  seo: ISeo
}

export interface IPost {
  id: number
  post_number: string
  studio_name: string
  studio_url: string
  email: string
  name: string
  en_name: string
  status: string
  address: string
  year: string
  medias: string[]
  urls: string[]
  description: string
  memo: string
  review_status: string
  en_review_status: string
  created_at: string
}

enum Api {
  profile = '/profile',
  updateProfile = '/profile',
  updatePassword = '/password',
  orders = '/orders',
  order = '/check_order/:id',
  cancelOrder = '/cancel_order/:id',
  favorites = '/favorites',
  toggleFavorite = '/toggle_favorite/:type/:id',
  posts = '/posts',
  submitPost = '/submit_post',
  viewlogs = '/view_logs'
}

export function getProfile(option?: HttpOption<any>) {
  return useHttp.get<IProfileResultModel>(Api.profile, option)
}

export function updateProfile(params: IProfileParams, option?: HttpOption<any>) {
  return useHttp.put<IProfileResultModel>(Api.updateProfile, params, option)
}

export function updatePassword(params: IPasswordParams, option?: HttpOption<any>) {
  return useHttp.put<IProfileResultModel>(Api.updatePassword, params, option)
}

export function getOrders(params?: IOrderFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IOrder[]>(Api.orders, params, option)
}

export function getOrder(id: string, option?: HttpOption<any>) {
  return useHttp.get<IOrder>(Api.order.replace(':id', id), option)
}

export function cancelOrder(id: string, option?: HttpOption<any>) {
  return useHttp.post<string>(Api.cancelOrder.replace(':id', id), {}, option)
}

export function getFavorites(params?: IFavoriteFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IFavorite[]>(Api.favorites, params, option)
}

export function getViewlogs(params?: IViewLogFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IViewlog[]>(Api.viewlogs, params, option)
}

export function toggleFavorite(type: string, id: number, option?: HttpOption<any>) {
  return useHttp.post<string>(Api.toggleFavorite.replace(':id', id.toString()).replace(':type', type), option)
}

export function getPosts(params?: IFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IPost[]>(Api.posts, params, option)
}

export function submitPost(params: any, option?: HttpOption<any>) {
  return useHttp.post<string>(Api.submitPost, params, option)
}
