import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IFilterParams } from './index'
import type { ICategory, IYearCategory } from './types/category'
import type { ILinks, IMeta, ISeo } from './types/response'
import type { ITag } from './types/tag'
import type { IBrand, IOffice } from './types/office'
import type { IContentBlock } from './types/block'

export interface ISelectionFilterParams extends IFilterParams {
  category_id: string
  'tags[]': string[]
}

export interface ISelectionSpec {
  id: number
  name: string
  color: string
  description: string
  note: string
  image: string
}

export interface ISelection {
  id: number
  title: string
  en_title: string
  subtitle: string
  category: ICategory
  brand: IBrand
  description: string
  notes: string
  content: IContentBlock[]
  price: number
  member_price: number
  sell_out: boolean
  images: string[]
  specs: ISelectionSpec[]
  tags: ITag[]
  seo: ISeo
  status: number
}

export interface ISelectionList {
  data: ISelection[]
  meta: IMeta
  links: ILinks
}

export interface ISelectionIndexResultModel {
  list: ISelectionList
  categories: ICategory[]
  popular_tags: ITag[]
  top_tags: ITag[]
}

export interface ISelectionResultModel {
  info: ISelection
  recommends: ISelection[]
}

enum Api {
  selections = '/selections',
  selection = '/selections/:id'
}

export function getSelections(params?: ISelectionFilterParams, option?: HttpOption<any>) {
  return useHttp.get<ISelectionIndexResultModel>(Api.selections, params, option)
}

export function getSelection(id: string, option?: HttpOption<any>) {
  return useHttp.get<ISelectionResultModel>(Api.selection.replace(':id', id), option)
}
