import { useUserStore } from '~/stores/user'

export default defineNuxtRouteMiddleware(to => {
  const iwssid = useCookie('iwssid')
  const userStore = useUserStore()
  if (isEmpty(iwssid.value)) {
    const randomCode = generateRandomCode(32)
    iwssid.value = randomCode
    userStore.setSession(randomCode)
  } else {
    userStore.setSession(iwssid.value)
  }
})
