import * as project from './project'
import * as opinion from './opinion'
import * as page from './page'
import * as news from './news'
import * as story from './story'
import * as selection from './selection'
import * as issue from './issue'
import * as contest from './contest'
import * as auth from './auth'
import * as user from './user'
import * as contact from './contact'
import * as cart from './cart'
import * as search from './search'

export default {
  page,
  opinion,
  project,
  news,
  story,
  selection,
  issue,
  contest,
  auth,
  user,
  contact,
  cart,
  search
}

/** 基本filter */
export interface IFilterParams {
  sorting: string
  direction: 'asc' | 'desc'
  page: number
  per_page: number
  q: string
}
