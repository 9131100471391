<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" display="none">
    <symbol id="icon-search" viewBox="0 0 17 17">
      <path
        d="M15.8713 15.4073L11.0779 10.477C13.0661 7.95577 12.9299 4.23 10.6422 1.87689C9.47104 0.672318 7.8914 0 6.23005 0C4.56871 0 2.98906 0.672318 1.81795 1.87689C-0.605983 4.37007 -0.605983 8.46001 1.81795 10.9532C2.98906 12.1578 4.56871 12.8301 6.23005 12.8301C7.67352 12.8301 9.06252 12.3258 10.1792 11.4014L14.9726 16.3317C15.1087 16.4718 15.2721 16.5278 15.4356 16.5278C15.599 16.5278 15.7624 16.4718 15.8986 16.3317C16.1164 16.0796 16.1164 15.6594 15.8713 15.4073ZM2.71671 10.0288C0.783013 8.03981 0.783013 4.79027 2.71671 2.80133C3.64271 1.84888 4.89553 1.31662 6.23005 1.31662C7.56458 1.31662 8.8174 1.84888 9.7434 2.80133C11.6771 4.79027 11.6771 8.03981 9.7434 10.0288C8.8174 10.9812 7.56458 11.5135 6.23005 11.5135C4.89553 11.5415 3.66995 11.0092 2.71671 10.0288Z"
        fill="currentColor" />
    </symbol>
    <symbol id="icon-check" viewBox="0 0 24 24" fill="none">
      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" stroke-width="1.5"
        stroke="currentColor" />
    </symbol>
    <symbol id="icon-member" viewBox="0 0 16 16">
      <path
        d="M10.2033 7.87713C12.1766 6.61846 12.7526 3.9998 11.4939 2.02646C10.2353 0.0531293 7.61125 -0.522871 5.64325 0.735796C3.66992 1.99446 3.09392 4.61313 4.35258 6.58646C4.68325 7.1038 5.12592 7.54646 5.64325 7.87713C3.16858 8.82646 1.53125 11.1998 1.53125 13.8505C1.53125 14.9971 2.46458 15.9305 3.61125 15.9305H12.2353C13.3819 15.9305 14.3153 14.9971 14.3153 13.8505C14.3153 11.1998 12.6779 8.82646 10.2033 7.87713ZM4.77925 4.31446C4.77925 2.5758 6.18725 1.17313 7.92058 1.17313C9.65392 1.17313 11.0619 2.58113 11.0619 4.31446C11.0619 6.0478 9.65392 7.4558 7.92058 7.4558C6.18725 7.4558 4.77925 6.0478 4.77925 4.31446ZM12.2353 14.8371H3.61125C3.06725 14.8371 2.62458 14.3945 2.62458 13.8505C2.62458 10.9225 4.99792 8.54913 7.92592 8.54913C10.8539 8.54913 13.2273 10.9225 13.2273 13.8505C13.2219 14.3945 12.7793 14.8371 12.2353 14.8371Z"
        fill="currentColor" />
    </symbol>
    <symbol id="icon-member-bg" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.2023 7.87734C12.1756 6.61868 12.7516 4.00001 11.4929 2.02668C10.2343 0.0533429 7.61027 -0.522657 5.64227 0.73601C3.66894 1.99468 3.09294 4.61334 4.35161 6.58668C4.68227 7.10401 5.12494 7.54668 5.64227 7.87734C3.16761 8.82668 1.53027 11.2 1.53027 13.8507C1.53027 14.9973 2.46361 15.9307 3.61027 15.9307H12.2343C13.3809 15.9307 14.3143 14.9973 14.3143 13.8507C14.3143 11.2 12.6769 8.82668 10.2023 7.87734ZM4.77827 4.31468C4.77827 2.57601 6.18627 1.17334 7.91961 1.17334C9.65294 1.17334 11.0609 2.58134 11.0609 4.31468C11.0609 6.04801 9.65294 7.45601 7.91961 7.45601C6.18627 7.45601 4.77827 6.04801 4.77827 4.31468ZM12.2343 14.8373H3.61027C3.06627 14.8373 2.62361 14.3947 2.62361 13.8507C2.62361 10.9227 4.99694 8.54934 7.92494 8.54934C10.8529 8.54934 13.2263 10.9227 13.2263 13.8507C13.2209 14.3947 12.7783 14.8373 12.2343 14.8373Z"
        fill="currentColor" />
      <path d="M11.5 4C11.5 5.933 9.433 8 7.5 8C5.567 8 4 6.433 4 4.5C4 2.567 5.567 1 7.5 1C9.433 1 11.5 2.067 11.5 4Z"
        fill="currentColor" />
      <path
        d="M13.5001 14.5002C13.8334 12.8335 13.4001 9.30001 9.00008 8.50001C4.60008 7.70001 2.83341 11.8333 2.50008 14C2.39434 14.5235 2.78935 14.885 3.00008 15.0002H9.50008C10.7001 15.0002 12.6667 14.6669 13.5001 14.5002Z"
        fill="currentColor" stroke="currentColor" />
    </symbol>
    <symbol id="icon-close" viewBox="0 0 28 29" fill="none">
      <path
        d="M16.3217 14.4996L27.519 2.90311C28.1603 2.23888 28.1603 1.16239 27.519 0.498166C26.8777 -0.166055 25.8383 -0.166055 25.197 0.498166L14.0003 12.0953L2.80302 0.498166C2.16169 -0.166055 1.12231 -0.166055 0.480991 0.498166C-0.16033 1.16239 -0.16033 2.23888 0.480991 2.90311L11.6783 14.5003L0.480991 26.0967C-0.16033 26.7609 -0.16033 27.8374 0.480991 28.5017C0.801316 28.8334 1.22149 29 1.64167 29C2.06184 29 2.48202 28.8341 2.80235 28.5017L13.9997 16.9045L25.197 28.5017C25.5173 28.8334 25.9375 29 26.3577 29C26.7778 29 27.198 28.8341 27.5183 28.5017C28.1597 27.8374 28.1597 26.7609 27.5183 26.0967L16.3217 14.4996Z"
        fill="currentColor" />
    </symbol>
    <symbol id="icon-cart" viewBox="0 0 16 16">
      <g clip-path="url(#clip0_1646_7380)">
        <path
          d="M14.2386 14.505L13.3953 3.96326C13.3761 3.72327 13.1758 3.53845 12.9352 3.53845H11.2429V3.23071C11.2429 1.44934 9.79357 0 8.01207 0C6.2307 0 4.78136 1.44934 4.78136 3.23071V3.53845H3.08898C2.84838 3.53845 2.64818 3.72327 2.62901 3.96326L1.78563 14.505C1.75487 14.8889 1.88719 15.2716 2.14842 15.5546C2.40978 15.8376 2.78063 16 3.16588 16H12.8584C13.2436 16 13.6144 15.8376 13.8757 15.5546C14.1371 15.2716 14.2693 14.889 14.2386 14.505ZM5.70446 3.23071C5.70446 1.95825 6.73961 0.923096 8.01207 0.923096C9.28453 0.923096 10.3198 1.95825 10.3198 3.23071V3.53845H5.70446V3.23071ZM13.1975 14.9285C13.1091 15.0242 12.9886 15.0769 12.8584 15.0769H3.16588C3.03551 15.0769 2.91515 15.0242 2.82677 14.9285C2.73827 14.8328 2.69542 14.7085 2.7058 14.5786L3.51512 4.46155H4.78136V6.15381C4.78136 6.40869 4.98802 6.61536 5.24291 6.61536C5.49779 6.61536 5.70446 6.40869 5.70446 6.15381V4.46155H10.3198V6.15381C10.3198 6.40869 10.5265 6.61536 10.7814 6.61536C11.0362 6.61536 11.2429 6.40869 11.2429 6.15381V4.46155H12.5091L13.3185 14.5786C13.3288 14.7085 13.2859 14.8328 13.1975 14.9285Z"
          fill="currentColor" />
        <path
          d="M11.7036 12.4619H4.31897C4.06409 12.4619 3.85742 12.6686 3.85742 12.9235C3.85742 13.1783 4.06409 13.385 4.31897 13.385H11.7036C11.9585 13.385 12.1652 13.1783 12.1652 12.9235C12.1652 12.6686 11.9585 12.4619 11.7036 12.4619Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_1646_7380">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </symbol>
    <symbol id="icon-cart2" viewBox="0 0 25 21" fill="none">
      <path
        d="M22.4163 19.2161H8.24493C7.91994 19.2161 7.62666 19.0497 7.45888 18.7709C7.29242 18.4921 7.28449 18.1539 7.43906 17.8672L9.42202 14.1839H22.4163C22.6356 14.1839 22.8245 14.028 22.8654 13.8113L24.6965 4.20258C24.7216 4.06914 24.6859 3.93042 24.6 3.82473C24.5128 3.72036 24.3834 3.65958 24.2473 3.65958H5.82073L4.76782 0.88517C4.56701 0.355389 4.05046 0 3.48504 0H0.457097C0.204769 0 0 0.204778 0 0.457118C0 0.709457 0.204769 0.914236 0.457097 0.914236H3.48504C3.67395 0.914236 3.84569 1.03314 3.91307 1.20885L8.64786 13.6911L6.6332 17.4312C6.32538 18.0033 6.33991 18.6797 6.67283 19.2373C7.00574 19.7961 7.59363 20.129 8.24361 20.129H22.4163C22.6686 20.129 22.8734 19.9243 22.8734 19.6719C22.8734 19.4196 22.6699 19.2161 22.4163 19.2161ZM7.38225 7.77761H10.5212V10.0658H8.25021L7.38225 7.77761ZM11.4367 7.77761H14.639V10.0658H11.4367V7.77761ZM18.7568 10.0658H15.5545V7.77761H18.7568V10.0658ZM19.6724 7.77761H23.0847L22.6488 10.0658H19.6724V7.77761ZM18.7568 10.9801V13.2683H15.5545V10.9801H18.7568ZM15.5545 6.86337V4.57514H18.7568V6.86337H15.5545ZM14.639 6.86337H11.4367V4.57514H14.639V6.86337ZM14.639 10.9801V13.2683H11.4367V10.9801H14.639ZM22.0384 13.2683H19.6724V10.9801H22.4744L22.0384 13.2683ZM23.2578 6.86337H19.671V4.57514H23.6938L23.2578 6.86337ZM10.5212 4.57514V6.86337H7.03613L6.16817 4.57514H10.5212ZM8.59766 10.9801H10.5225V13.2683H9.46561L8.59766 10.9801Z"
        fill="currentColor" />
    </symbol>
    <symbol id="icon-lan-tw" viewBox="0 0 17 17">
      <path
        d="M14.684 4.99354C14.4503 4.75979 14.1019 4.64215 13.6481 4.64215H9.11753V2.01778L9.11677 2.01247C9.08034 1.75519 8.93995 1.61631 8.69938 1.59961H8.69482H8.69027C8.37533 1.61706 8.19395 1.77113 8.19395 2.02309V4.64291H3.84624C2.9257 4.64291 2.45898 5.10965 2.45898 6.03023V9.79148C2.45898 10.259 2.57206 10.5997 2.80504 10.8327C3.03802 11.0657 3.37876 11.1788 3.84624 11.1788H8.19395V15.4652C8.19395 15.7172 8.37457 15.8713 8.69027 15.8887H8.69482H8.69938C8.93995 15.872 9.08034 15.7324 9.11677 15.4759L9.11753 15.4705V11.1788H13.6481C14.5687 11.1788 15.0354 10.7121 15.0354 9.79148V6.02948C15.0354 5.57564 14.917 5.22729 14.684 4.99354ZM14.1118 6.15242V9.6693C14.1118 9.86814 14.0625 10.0176 13.9653 10.1133C13.8697 10.2074 13.7225 10.2552 13.5267 10.2559H9.11829V5.56729H13.5267C13.9153 5.56729 14.1118 5.76385 14.1118 6.15242ZM8.19319 5.56653V10.2544H3.96843C3.56394 10.2544 3.38332 10.0738 3.38332 9.6693V6.15166C3.38332 5.95662 3.43113 5.80863 3.52523 5.713C3.62085 5.61586 3.77035 5.56653 3.96843 5.56653H8.19319Z"
        fill="currentColor" />
    </symbol>
    <symbol id="icon-lan-en" viewBox="0 0 23 14">
      <path
        d="M1.67564 1.58747H8.33197C8.85856 1.58747 9.12694 1.36983 9.12694 0.940191C9.12694 0.635721 8.98937 0.271484 8.33197 0.271484H0.920136C0.158994 0.271484 0 0.703382 0 1.06536V12.9081C0 13.2701 0.160122 13.7031 0.920136 13.7031H8.33197C8.85856 13.7031 9.12694 13.4855 9.12694 13.0559C9.12694 12.7514 8.98937 12.3871 8.33197 12.3871H1.69706C1.37795 12.3871 1.31593 12.2699 1.31593 12.0691V7.42541H7.42987C8.13914 7.42541 8.24514 7.00705 8.24514 6.7567C8.24514 6.54921 8.16733 6.38006 8.02187 6.26955C7.88205 6.16355 7.68359 6.10942 7.42987 6.10942H1.31593V1.96862C1.31593 1.70137 1.42305 1.58747 1.67564 1.58747Z"
        fill="currentColor" />
      <path
        d="M21.8564 0.207491C21.7437 0.0699154 21.5802 0 21.3704 0C20.9397 0 20.7017 0.275151 20.7017 0.77358V11.3782L12.1059 0.417238L12.1037 0.414982C11.8691 0.134193 11.7349 0 11.6053 0C11.4057 0 11.2534 0.0721711 11.1542 0.214257C11.064 0.342811 11.02 0.526621 11.02 0.77358V13.205C11.02 13.7316 11.2377 14 11.6673 14C11.9086 14 12.3145 13.8963 12.3145 13.205V2.59928L20.9092 13.5591C21.1359 13.8545 21.3276 13.9977 21.4933 13.9977C21.6647 13.9977 21.8034 13.9211 21.8925 13.7756C21.9737 13.6425 22.0154 13.4508 22.0154 13.2027V0.772453C22.0188 0.528877 21.9647 0.339428 21.8564 0.207491Z"
        fill="currentColor" />
    </symbol>
    <symbol id="icon-video" viewBox="0 0 42 43" fill="none">
      <circle cx="21" cy="21.6953" r="20" stroke="currentColor" stroke-width="2" />
      <path d="M29 21.6953L17 30.3556L17 13.0351L29 21.6953Z" fill="currentColor" />
    </symbol>
    <symbol id="icon-stop" viewBox="0 0 42 43" fill="none">
      <circle cx="21" cy="21.6953" r="20" stroke="currentColor" stroke-width="2" />
      <rect x="16" y="16" width="10" height="10" fill="currentColor" />
    </symbol>
    <symbol id="icon-fav" viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
    </symbol>
    <symbol id="icon-fav2" viewBox="0 0 16 15" fill="none">
      <g clip-path="url(#clip0_2851_902)">
        <path
          d="M12.9018 0.770024C11.3185 0.341942 9.21849 0.855641 8.00182 2.55085C6.68516 0.752901 4.58516 0.324819 3.06849 0.752901C1.35182 1.21523 0.285156 2.67071 0.285156 4.57139C0.285156 8.88646 7.46849 14.1091 7.76849 14.3317C7.83516 14.383 7.91849 14.4002 7.98516 14.4002C8.06849 14.4002 8.13516 14.383 8.20182 14.3317C8.50182 14.1091 15.6852 8.88646 15.6852 4.57139C15.7185 2.68783 14.6352 1.23235 12.9018 0.770024ZM11.4018 10.5474C9.98516 11.9687 8.53516 13.0988 8.00182 13.5098C7.46849 13.0988 6.03516 11.9858 4.61849 10.5645C2.28516 8.21865 1.06849 6.16386 1.06849 4.57139C1.06849 3.04742 1.88516 1.90016 3.26849 1.52345C3.55182 1.43783 3.86849 1.40359 4.20182 1.40359C5.43516 1.40359 6.83516 2.0029 7.66849 3.49263C7.73516 3.61249 7.86849 3.69811 8.00182 3.69811C8.13516 3.69811 8.26849 3.61249 8.33516 3.49263C9.30182 1.69468 11.2518 1.16386 12.7018 1.54057C14.1018 1.91728 14.9352 3.04742 14.9352 4.57139C14.9352 6.14674 13.7185 8.21865 11.4018 10.5474Z"
          fill="currentColor" />
        <path
          d="M4.80007 1.1837C5.76007 1.1837 7.46645 2.49964 7.99978 3.15754C12.4001 -1.18443 15.1998 1.97346 15.1998 4.73648C15.6001 5.92069 10.8001 12.6312 8.00008 13.8154C5.60007 12.2365 1.2 8.28896 0.8 5.13106C0.4 1.97317 3.60007 1.1837 4.80007 1.1837Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_2851_902">
          <rect width="16" height="15" fill="white" />
        </clipPath>
      </defs>
    </symbol>
    <symbol id="icon-arrow-right" viewBox="0 0 23 11" fill="none">
      <path d="M0 5.30078L21.5 5.30078" stroke="currentColor" />
      <path d="M15.2461 9.59961L21.5006 5.29961L15.2461 0.99961" stroke="currentColor" />
    </symbol>
    <symbol id="icon-arrow-right2" viewBox="0 0 32 14" fill="none">
      <path d="M0 7.16797L30.2734 7.16797" stroke="currentColor" />
      <path d="M21.3027 13.332L30.2726 7.16523L21.3027 0.998436" stroke="currentColor" />
    </symbol>
    <symbol id="icon-arrow-right3" width="33" height="52" viewBox="0 0 33 52" fill="none">
      <g filter="url(#filter0_d_2557_104)">
        <path d="M5 43L28 22L5 0.999995" stroke="currentColor" />
      </g>
      <defs>
        <filter id="filter0_d_2557_104" x="0.662109" y="0.628906" width="32.0801" height="50.7422"
          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2557_104" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2557_104" result="shape" />
        </filter>
      </defs>
    </symbol>
    <symbol id="icon-arrow-right4" viewBox="0 0 32 60" fill="none">
      <g filter="url(#filter0_d_2752_170)">
        <path d="M5.19727 50.7852L25.5466 25.8963L5.19727 1.00735" stroke="currentColor" stroke-width="3" />
      </g>
      <defs>
        <filter id="filter0_d_2752_170" x="0.0351562" y="0.0585938" width="31.4492" height="59.6758"
          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2752_170" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2752_170" result="shape" />
        </filter>
      </defs>
    </symbol>
    <symbol id="icon-arrow-right5" viewBox="0 0 13 22" fill="none">
      <path d="M1 21L12 11L1 0.999998" stroke="currentColor" />
    </symbol>
    <symbol id="icon-arrow-down" viewBox="0 0 8 6" fill="none">
      <path d="M4 6L0.535898 0.75L7.4641 0.75L4 6Z" fill="currentColor" />
    </symbol>
    <symbol id="icon-arrow-down2" viewBox="0 0 10 21" fill="none">
      <path d="M5 0L5 19.6362" stroke="currentColor" stroke-width="0.5" />
      <path d="M1 13.8184L4.99997 19.6365L8.99994 13.8184" stroke="currentColor" stroke-width="0.5" />
    </symbol>
    <symbol id="icon-filter" viewBox="0 0 22 22" fill="none">
      <path
        d="M8.86158 20.63L8.8574 20.6283C8.72006 20.5712 8.62429 20.4306 8.62429 20.2741V9.90952C8.62429 9.31468 8.38522 8.73286 7.96642 8.31092L0.861325 1.15245C0.751131 1.04142 0.719358 0.877752 0.777911 0.734739C0.83591 0.593077 0.975563 0.5 1.12499 0.5H20.3731C20.5214 0.5 20.6584 0.588112 20.7207 0.736232C20.7793 0.880985 20.7446 1.0455 20.6396 1.14957L20.6367 1.15245L13.5316 8.31092C13.1128 8.73286 12.8738 9.31468 12.8738 9.90952V16.7482C12.8738 16.8497 12.8325 16.9487 12.7625 17.0192L9.26287 20.5451C9.19106 20.6174 9.09549 20.6556 8.9992 20.6556C8.94464 20.6556 8.89843 20.6458 8.86158 20.63Z"
        stroke="currentColor" />
    </symbol>
    <symbol id="icon-circle-dot" viewBox="0 0 31 3" fill="none">
      <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="8.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="15.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="22.5" cy="1.5" r="1.5" fill="currentColor" />
      <circle cx="29.5" cy="1.5" r="1.5" fill="currentColor" />
    </symbol>
    <symbol id="icon-share" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
    </symbol>
    <symbol id="filter-list" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M6 10h12M3 6h18M3 14h18M6 18h12" stroke="#000000" stroke-width="1" stroke-miterlimit="10"
          stroke-linecap="round"></path>
      </g>
    </symbol>
    <symbol id="icon-edit" fill="none" viewBox="0 0 18 20">
      <path
        d="M1.8098 18.6272C3.00858 18.0356 4.20607 17.4452 5.40356 16.8536C5.45017 16.8691 5.49678 16.8743 5.54209 16.8717C5.54856 16.8717 5.55503 16.8717 5.5628 16.8717C5.56668 16.8704 5.57057 16.8717 5.57445 16.8704C5.67284 16.8665 5.77252 16.8264 5.85796 16.7306C9.06595 13.1768 12.2752 9.62297 15.4832 6.06788C15.9389 5.56427 16.3933 5.05936 16.849 4.55574C16.8827 4.52208 16.906 4.48842 16.9241 4.45346C17.6581 3.45529 17.6426 2.07003 16.6768 1.19873C15.7175 0.332615 14.1847 0.411589 13.32 1.37092C10.112 4.92471 6.90269 8.47851 3.69471 12.0336C3.24031 12.5372 2.78462 13.0421 2.33022 13.5457C2.2163 13.6713 2.19429 13.8539 2.25643 14.0028C2.13215 14.3368 2.00787 14.6708 1.88359 15.0048C1.82404 15.1627 1.76578 15.322 1.70623 15.4799C1.36446 16.3965 1.02398 17.3132 0.682214 18.2285C0.682214 18.2285 0.397405 18.9483 0.651144 19.0078C0.902293 19.0674 1.8098 18.6272 1.8098 18.6272ZM16.4399 3.60806C15.7227 2.96074 15.0055 2.31212 14.287 1.6648C15.4819 0.881544 17.0328 2.29529 16.4399 3.60806ZM12.5924 3.45918C12.951 3.06172 13.3109 2.66427 13.6695 2.26681C14.4204 2.9452 15.1699 3.6223 15.9208 4.2994C12.8086 7.74704 9.69641 11.1947 6.58293 14.6423C6.22433 15.0398 5.86444 15.4372 5.50584 15.8347C4.75498 15.1563 4.00541 14.4792 3.25455 13.8021C6.36803 10.3544 9.48021 6.90681 12.5924 3.45918ZM2.85971 14.8313C3.41379 15.3323 3.96917 15.8334 4.52454 16.3344C4.01448 16.5856 3.5057 16.8367 2.99564 17.0892C2.75225 16.8691 2.50887 16.649 2.26549 16.4289C2.46356 15.8955 2.66163 15.3634 2.85971 14.8313Z"
        fill="currentColor" />
    </symbol>
    <symbol id="icon-eye" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </symbol>
    <symbol id="icon-fb" fill="none" viewBox="0 0 24 24">
      <path
        d="M23.9466 12.096C23.9466 5.71195 18.7009 0.527954 12.2409 0.527954C5.78087 0.527954 0.535156 5.71195 0.535156 12.096C0.535156 17.88 4.80944 22.656 10.4194 23.52V15.456H7.4323V12.096H10.3952V9.55195C10.3952 6.64795 12.1437 5.03995 14.8152 5.03995C16.1023 5.03995 17.438 5.25595 17.438 5.25595V8.11195H15.9566C14.4994 8.11195 14.038 8.99995 14.038 9.91195V12.072H17.2923L16.8066 15.456H14.0623V23.544C19.6723 22.656 23.9466 17.88 23.9466 12.096Z"
        fill="#2F6DB6" />
    </symbol>
    <symbol id="icon-line" fill="none" viewBox="0 0 24 24">
      <g>
        <g id="login_line 1">
          <g id="Group">
            <path fill="#66AF30"
              d="m12.1788,23.616c6.5546,0 11.8682,-5.1899 11.8682,-11.592c0,-6.40208 -5.3136,-11.59199 -11.8682,-11.59199c-6.5547,0 -11.86825,5.18991 -11.86825,11.59199c0,6.4021 5.31355,11.592 11.86825,11.592z"
              id="Vector" />
            <g id="Group_2">
              <g id="FULL_COLOR_ICON_3_">
                <g id="TYPE_A_3_">
                  <g id="Group_3">
                    <path fill="white"
                      d="m20.2386,11.424c0,-3.52801 -3.6366,-6.40801 -8.0841,-6.40801c-4.4721,0 -8.08419,2.88 -8.08419,6.40801c0,3.168 2.87489,5.832 6.75729,6.336c0.2703,0.048 0.6143,0.168 0.7126,0.384c0.0737,0.192 0.0491,0.504 0.0245,0.72c0,0 -0.0983,0.552 -0.1228,0.672c-0.0246,0.192 -0.172,0.792 0.7126,0.432c0.86,-0.36 4.6686,-2.688 6.3641,-4.584c1.1548,-1.272 1.72,-2.544 1.72,-3.96z"
                      id="Vector_2" />
                    <g id="Group_4">
                      <path fill="#66AF30"
                        d="m10.5095,9.71997l-0.5652,0c-0.0983,0 -0.1474,0.072 -0.1474,0.144l0,3.43203c0,0.096 0.0737,0.144 0.1474,0.144l0.5652,0c0.0982,0 0.1474,-0.072 0.1474,-0.144l0,-3.40803c0,-0.096 -0.0737,-0.168 -0.1474,-0.168z"
                        id="Vector_3" />
                      <path fill="#66AF30"
                        d="m14.415,9.71997l-0.5651,0c-0.0983,0 -0.1474,0.072 -0.1474,0.144l0,2.04003l-1.6218,-2.13603c0,0 0,0 0,-0.024l-0.7126,0c-0.0983,0 -0.1474,0.072 -0.1474,0.144l0,3.43203c0,0.096 0.0737,0.144 0.1474,0.144l0.5652,0c0.0983,0 0.1474,-0.072 0.1474,-0.144l0,-2.04l1.6218,2.136c0,0.024 0.0245,0.024 0.0491,0.048c0.0246,0 0.0246,0 0.0491,0l0.5652,0c0.0983,0 0.1474,-0.072 0.1474,-0.144l0,-3.43203c0.0492,-0.096 -0.0245,-0.168 -0.0983,-0.168z"
                        id="Vector_4" />
                      <path fill="#66AF30"
                        d="m9.1327,12.6l-1.5481,0l0,-2.71198c0,-0.096 -0.0737,-0.144 -0.1474,-0.144l-0.5652,0c-0.0982,0 -0.1474,0.072 -0.1474,0.144l0,3.43198c0,0.048 0.0246,0.072 0.0492,0.096c0.0245,0.024 0.0737,0.048 0.0982,0.048l2.2607,0c0.0982,0 0.1474,-0.072 0.1474,-0.144l0,-0.552c0.0246,-0.096 -0.0492,-0.168 -0.1474,-0.168z"
                        id="Vector_5" />
                      <path fill="#66AF30"
                        d="m17.5369,10.584c0.0983,0 0.1475,-0.072 0.1475,-0.144l0,-0.55198c0,-0.096 -0.0737,-0.144 -0.1475,-0.144l-2.2606,0c-0.0491,0 -0.0737,0.024 -0.0982,0.048c-0.0246,0.024 -0.0492,0.072 -0.0492,0.096l0,3.43198c0,0.048 0.0246,0.072 0.0492,0.096c0.0245,0.024 0.0737,0.048 0.0982,0.048l2.2606,0c0.0983,0 0.1475,-0.072 0.1475,-0.144l0,-0.552c0,-0.096 -0.0737,-0.144 -0.1475,-0.144l-1.548,0l0,-0.576l1.548,0c0.0983,0 0.1475,-0.072 0.1475,-0.144l0,-0.552c0,-0.096 -0.0737,-0.144 -0.1475,-0.144l-1.548,0l0,-0.576l1.548,0l0,-0.048z"
                        id="Vector_6" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </symbol>
    <symbol id="icon-google" fill="none" viewBox="0 0 24 24">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M22.5755 18.408C22.0755 19.2 21.5005 19.92 20.7505 20.5201C19.5005 21.6721 18.0255 22.416 16.3505 22.824C12.4255 23.784 8.82546 23.184 5.60046 20.736C4.30046 19.7521 3.25046 18.504 2.50046 17.0401C0.850461 14.088 0.800461 9.91205 2.50046 6.93605C3.67546 4.41605 6.77546 1.56005 11.0005 0.912052C14.6005 0.360052 17.8255 1.17605 20.6005 3.48005C20.7755 3.62405 20.8255 3.72005 20.6255 3.88805C17.3505 6.96005 17.6005 6.93605 17.3755 6.72005C14.0755 3.81605 8.20046 4.92005 6.37546 9.76805C5.87546 11.2321 5.90046 12.6961 6.35046 14.1601C6.70046 15.024 7.15046 15.8161 7.77546 16.5121C10.4005 19.2961 14.2005 19.392 17.0755 17.7121C18.4005 16.7281 18.9255 15.816 19.3005 14.592C19.3755 14.352 19.3505 14.2561 19.0255 14.2561C17.1255 14.2801 15.2505 14.2561 13.3505 14.2561C12.9505 14.2561 12.9505 14.2561 12.9505 13.8481C12.9505 12.6241 12.9505 11.4001 12.9505 10.1521C12.9505 9.91205 13.0005 9.81605 13.2755 9.81605C16.7505 9.81605 20.2505 9.81605 23.7255 9.81605C23.9255 9.81605 24.0255 9.84005 24.0755 10.0561C24.5005 13.0321 24.1505 15.84 22.5755 18.408Z"
        fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M22.5512 18.4081C22.0512 19.2001 21.4762 19.9201 20.7512 20.5201C16.6762 17.4721 17.4512 18.0721 17.0762 17.7361C18.4012 16.7521 18.9262 15.8401 19.3012 14.6161C19.3762 14.3761 19.3512 14.2801 19.0262 14.2801C17.1262 14.3041 15.2512 14.2801 13.3512 14.2801C12.9512 14.2801 12.9512 14.2801 12.9512 13.8721C12.9512 12.6481 12.9512 11.4241 12.9512 10.1761C12.9512 9.93609 13.0012 9.84009 13.2762 9.84009C16.7512 9.84009 20.2512 9.84009 23.7262 9.84009C23.9262 9.84009 24.0262 9.86409 24.0762 10.0801C24.5012 13.0321 24.1512 15.8401 22.5512 18.4081Z"
        fill="#6383BE" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M20.75 20.5201C19.5 21.6721 18.025 22.4161 16.35 22.8241C12.425 23.7841 8.825 23.1841 5.6 20.7361C4.275 19.7521 3.25 18.5041 2.5 17.0401C3.775 16.0801 5.05 15.1441 6.325 14.1841C6.675 15.0481 7.125 15.8401 7.75 16.5361C10.375 19.3201 14.175 19.4161 17.05 17.7361C17.425 18.0481 16.65 17.4721 20.75 20.5201Z"
        fill="#4EAF4B" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M6.3258 14.1841C5.0508 15.1201 3.7758 16.0801 2.5008 17.0401C0.850799 14.0881 0.825799 9.96008 2.5008 6.96008C6.7508 10.0801 6.1008 9.62408 6.3758 9.76808C5.8508 11.2321 5.8758 12.7201 6.3258 14.1841Z"
        fill="#F5C722" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M20.6254 3.88805C17.3504 6.96005 17.6004 6.93605 17.3754 6.72005C14.0754 3.81605 8.20039 4.92005 6.37539 9.76805C6.12539 9.60005 6.77539 10.0801 2.52539 6.93605C3.70039 4.44005 6.77539 1.56005 11.0004 0.912052C14.6004 0.360052 17.8254 1.17605 20.6004 3.48005C20.7754 3.62405 20.8254 3.69605 20.6254 3.88805Z"
        fill="#D53331" />
    </symbol>
    <symbol id="icon-eye" fill="#A9ABB2" viewBox="0 0 576 512">
      <path
        d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
    </symbol>
    <symbol id="icon-eye-slash" fill="#A9ABB2" viewBox="0 0 640 512">
      <path
        d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z" />
    </symbol>
  </svg>
</template>
