import { useHttp, type HttpOption } from '../composables/useHttp'

export interface IContactParams {
  category: string
  name: string
  email: string
  phone: string
  content: string
  google_recaptcha_token: string
}

export interface ISubscribeEmailParams {
  email: string
}

enum Api {
  submitContact = '/submit_contact',
  subscribeEmail = '/subscribe_email'
}

export async function submitContact(params: IContactParams, option?: HttpOption<any>) {
  return useHttp.post<string>(Api.submitContact, params, option)
}

export async function subscribeEmail(params: ISubscribeEmailParams, option?: HttpOption<any>) {
  return useHttp.post<string>(Api.subscribeEmail, params, option)
}
