import { useHttp, type HttpOption } from '../composables/useHttp'

export interface ILoginParams {
  email: string
  password: string
}

export interface ILoginResultModel {
  token_type: string
  token: string
}

export interface IRegisterParams {
  role: 'regular' | 'designer'
  email: string
  password: string
  confirm_password: string
  first_name: string
  last_name: string
  phone_code: string
  phone: string
  studio_name: string
  studio_address: string
  studio_url: string
  is_subscribe: boolean
  is_subscribe_second: boolean
  is_subscribe_third: boolean
  google_recaptcha_token: string
}

export interface IForgotPasswordParams {
  email: string
}

export interface IResetPasswordParams {
  token: string
  email: string
  password: string
}

export interface ISocialLoginParams {
  code: string
  state: string
}

enum Api {
  login = '/auth/login',
  logout = '/auth/logout',
  register = '/auth/register',
  forgotPassword = '/auth/forgot_password',
  resetPassword = '/auth/reset_password',
  resendVerify = '/auth/resend_verify',
  googleLogin = '/auth/google_login_callback',
  facebookLogin = '/auth/facebook_login_callback',
  lineLogin = '/auth/line_login_callback'
}

export async function login(params: ILoginParams, option?: HttpOption<any>) {
  return useHttp.post<ILoginResultModel>(Api.login, params, option)
}

export async function logout(option?: HttpOption<any>) {
  return useHttp.post<void>(Api.logout, {}, option)
}

export async function register(params: IRegisterParams, option?: HttpOption<any>) {
  return useHttp.post<string>(Api.register, params, option)
}

export async function forgotPassword(params: IForgotPasswordParams, option?: HttpOption<any>) {
  return useHttp.post<string>(Api.forgotPassword, params, option)
}

export async function resetPassword(params: IResetPasswordParams, option?: HttpOption<any>) {
  return useHttp.post<string>(Api.resetPassword, params, option)
}

export async function lineLogin(params: ISocialLoginParams, option?: HttpOption<any>) {
  return useHttp.post<ILoginResultModel>(Api.lineLogin, params, option)
}

export async function googleLogin(params: ISocialLoginParams, option?: HttpOption<any>) {
  return useHttp.post<ILoginResultModel>(Api.googleLogin, params, option)
}

export async function facebookLogin(params: ISocialLoginParams, option?: HttpOption<any>) {
  return useHttp.post<ILoginResultModel>(Api.facebookLogin, params, option)
}
