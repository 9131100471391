import revive_payload_client_4sVQNw7RlN from "/www/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/www/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/www/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/www/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/www/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/www/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/www/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/www/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/www/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/www/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/www/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_iGLjBbor9q from "/www/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/www/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/www/.nuxt/element-plus-injection.plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/www/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/www/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import ssr_plugin_B4ptqVdIfe from "/www/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import plugin_AUP22rrBAc from "/www/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import fontawesome_cn2c4tOOHz from "/www/plugins/fontawesome.ts";
import google_recaptcha_rYK9Kziv68 from "/www/plugins/google-recaptcha.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  plugin_iGLjBbor9q,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  ssr_plugin_B4ptqVdIfe,
  plugin_AUP22rrBAc,
  fontawesome_cn2c4tOOHz,
  google_recaptcha_rYK9Kziv68
]