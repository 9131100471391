import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IFilterParams } from './index'
import type { ICategory } from './types/category'
import type { ILinks, IMeta, ISeo } from './types/response'
import type { ITag } from './types/tag'
import type { IBrand, IOffice } from './types/office'
import type { IContentBlock } from './types/block'

export interface IStoryFilterParams extends IFilterParams {
  category_id: string
  type: string
  'tags[]': string[]
}

export interface IStory {
  id: number
  image: string
  title: string
  second_title: string
  description: string
  en_title: string
  subtitle: string
  category: ICategory
  brand: IBrand
  content: IContentBlock[]
  tags: ITag[]
  sale_selection: null
  no_sale_selection: {
    images: string
    title: string
    en_title: string
    description: string
  }
  seo: ISeo
  status: number
}

export interface IStoryList {
  data: IStory[]
  meta: IMeta
  links: ILinks
}

export interface IStoryIndexResultModel {
  top: IStory[]
  list: IStoryList
  top_tags: ITag[]
  categories: ICategory[]
}

export interface IStoryResultModel {
  info: IStory
  recommends: IStory[]
}

enum Api {
  Stories = '/stories',
  Story = '/stories/:id'
}

export function getStories(params?: IStoryFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IStoryIndexResultModel>(Api.Stories, params, option)
}

export function getStory(id: string, option?: HttpOption<any>) {
  return useHttp.get<IStoryResultModel>(Api.Story.replace(':id', id), option)
}
