import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IFilterParams } from './index'
import type { ICategory } from './types/category'
import type { ILinks, IMeta, ISeo } from './types/response'
import type { ITag } from './types/tag'
import type { IBrand, IOffice } from './types/office'
import type { IContentBlock } from './types/block'

export interface INewsFilterParams extends IFilterParams {
  category_id: string
  'tags[]': string[]
}

export interface INews {
  id: number
  title: string
  en_title: string | null
  subtitle: string | null
  publish_date: string
  description: string
  content: IContentBlock[]
  category: string
  brand: IBrand
  image: string
  tags: ITag[]
  seo: ISeo
  status: number
}

export interface INewsList {
  data: INews[]
  meta: IMeta
  links: ILinks
}

export interface INewsIndexResultModel {
  list: INewsList
  top_tags: ITag[]
}

export interface INewsResultModel {
  info: INews
  recommends: INews[]
}

enum Api {
  Newss = '/reports',
  News = '/reports/:id'
}

export function getNewss(params?: INewsFilterParams, option?: HttpOption<any>) {
  return useHttp.get<INewsIndexResultModel>(Api.Newss, params, option)
}

export function getNews(id: string, option?: HttpOption<any>) {
  return useHttp.get<INewsResultModel>(Api.News.replace(':id', id), option)
}
