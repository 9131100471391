import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IFilterParams } from './index'
import type { ICategory } from './types/category'
import type { ILinks, IMeta, ISeo } from './types/response'
import type { ITag } from './types/tag'
import type { IBrand, IOffice } from './types/office'
import type { IContentBlock } from './types/block'

export interface IContestFilterParams extends IFilterParams {
  category_id: string
  type: string
  'tags[]': string[]
}

export interface IJudger {
  avatar: string
  name: string
  en_name: string
  description: string
}

export interface IContest {
  id: number
  title: string
  en_title: string
  subtitle: string
  category: ICategory
  content: IContentBlock[]
  image: string
  deadline: string
  winners: IJudger[]
  judges: IJudger[]
  tags: ITag[]
  seo: ISeo
  status: number
}

export interface IContestList {
  data: IContest[]
  meta: IMeta
  links: ILinks
}

export interface IContestIndexResultModel {
  top: IContest[]
  list: IContestList
  top_tags: ITag[]
  categories: ICategory[]
}

export interface IContestResultModel {
  info: IContest
  recommends: IContest[]
}

enum Api {
  Stories = '/contests',
  Contest = '/contests/:id'
}

export function getContests(params?: IContestFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IContestIndexResultModel>(Api.Stories, params, option)
}

export function getContest(id: string, option?: HttpOption<any>) {
  return useHttp.get<IContestResultModel>(Api.Contest.replace(':id', id), option)
}
