import { type IReCaptchaComposition } from 'vue-recaptcha-v3'
import { useDateFormat, useWindowSize } from '@vueuse/core'
import type { IFavorite, IViewlog } from '../api/user'
import type { ISearchItem } from '~/api/search'
import _ from 'lodash'

const { width } = useWindowSize()

export function objectToSearch(params: Record<string, any>) {
  let searchParams = ''
  if (Object.keys(params).length > 0) {
    for (const key in params) {
      if (!isEmpty(params[key])) {
        searchParams += `${key}=${params[key]}&`
      }
    }
    searchParams = searchParams.slice(0, searchParams.length - 1)
  }
  return searchParams
}

// empty/ null/ undefined
export function isEmpty(value: any) {
  if (value === null || typeof value === 'undefined') {
    return true
  }

  if (typeof value === 'string' && value.trim().length === 0) {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true
  }

  return false
}

export function extractVideoID(url: string) {
  const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/
  const match = url?.match(regex)
  return match ? match[1] : null
}

export function extractInstagramID(url: string) {
  const patterns = [/instagram\.com\/p\/([^/?#&]+)/i, /instagram\.com\/reel\/([^/?#&]+)/i, /instagram\.com\/tv\/([^/?#&]+)/i, /instagram\.com\/([^/?#&]+)/i]

  for (let pattern of patterns) {
    const match = url?.match(pattern)
    if (match) {
      return match[1]
    }
  }

  return null
}

export async function useReCaptchaV3(useReCaptcha: IReCaptchaComposition, action: string) {
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha
  await recaptchaLoaded()
  const token = await executeRecaptcha(action)

  return token
}

export function formatNumber(number: number) {
  return new Intl.NumberFormat().format(number)
}

export function monthsDiff(date1: string, date2: string) {
  let startDate = new Date(date1)
  let endDate = new Date(date2)

  let months = (endDate.getFullYear() - startDate.getFullYear()) * 12
  months -= startDate.getMonth()
  months += endDate.getMonth()

  return months <= 0 ? 0 : months
}

export function dateFormat(date: any, format: string = 'YYYY-MM-DD', locales: string = 'zh-TW'): string {
  const v = useDateFormat(date, format, { locales })
  return v.value
}

export const objectToBase64 = (data: any) => {
  const jsonStr = JSON.stringify(data)
  const base64Str = btoa(jsonStr)

  return base64Str
}

export const base64ToObject = (base64Str: string) => {
  try {
    const decode = decodeURIComponent(base64Str)
    const jsonStr = atob(decode)
    return JSON.parse(jsonStr)
  } catch (error) {
    console.error('解析 JSON 參數:', base64Str)
    console.error('解析 JSON 錯誤:', error)
  }

  return null
}

// 過濾Object
export const filterObject = (obj: any, fn: any) => {
  return Object.keys(obj).reduce((filteredObj: any, key) => {
    if (obj[key] !== fn) {
      filteredObj[key] = obj[key]
    }
    return filteredObj
  }, {})
}

// 判斷是否為空值
export const isNullOrEmptyOrUndefined = (str: string) => {
  return str === null || str === undefined || str === ''
}

// 轉換為form data
export const objectToFormData = (obj: Object) => {
  const formData = new FormData()

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => formData.append(`${key}[]`, item))
    } else {
      formData.append(key, value)
    }
  })

  return formData
}

// 判斷是否為手機
export const isMobile = () => {
  if (process.client) {
    const userAgent = navigator.userAgent.toLowerCase()

    return /iphone|ipad|ipod|android/i.test(userAgent)
  }

  return false
}

// 發票qr code解析
export const parseInvoiceQRCode = (qrCode: string) => {
  const invoiceData = {
    invoiceNumber: '',
    invoiceDate: '',
    randomNumber: '',
    salesAmount: 0,
    totalAmount: 0,
    buyerId: '',
    sellerId: ''
  }

  if (qrCode && qrCode.length >= 55) {
    // 發票字軌
    invoiceData.invoiceNumber = qrCode.substring(0, 10)
    // 發票開立日期
    const rocDate = qrCode.substring(10, 17)
    const year = parseInt(rocDate.substring(0, 3), 10) + 1911 // 民國年轉西元年
    const month = rocDate.substring(3, 5)
    const day = rocDate.substring(5, 7)
    invoiceData.invoiceDate = `${year}-${month}-${day}`
    // 隨機碼
    invoiceData.randomNumber = qrCode.substring(17, 21)
    // 銷售額 (需從十六進位轉為十進位)
    invoiceData.salesAmount = parseInt(qrCode.substring(21, 29), 16)
    // 總計額 (需從十六進位轉為十進位)
    invoiceData.totalAmount = parseInt(qrCode.substring(29, 37), 16)
    // 買方統一編號
    invoiceData.buyerId = qrCode.substring(37, 45)
    // 賣方統一編號
    invoiceData.sellerId = qrCode.substring(45, 53)
  }

  return invoiceData
}

// 判斷是否為email
export const isEmail = (str: string) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  return regex.test(str)
}

export const repairTypeDisplay = (type: string) => {
  let display = '維修'
  if (type === 'deep') {
    display = '深層清潔'
  }
  if (type === 'fast') {
    display = '速修'
  }
  return display
}

// google calendar
export const createGoogleCalendarEvent = (title: string, content: string, eventDateTime?: string) => {
  if (process.client && eventDateTime) {
    const formattedTitle = encodeURIComponent(title)
    const formattedContent = encodeURIComponent(content)
    const bookingStartDate = dateFormat(eventDateTime, 'YYYYMMDDTHHmmss')
    const newDate = new Date(eventDateTime)
    newDate.setHours(newDate.getHours() + 1)
    const bookingEndDate = dateFormat(newDate, 'YYYYMMDDTHHmmss')

    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${formattedTitle}&details=${formattedContent}&dates=${bookingStartDate}/${bookingEndDate}`
    window.open(googleCalendarUrl, '_blank')
  }
}
// 生成隨機碼
export const generateRandomCode = (length: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const randomCode = _.times(length, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('')
  return randomCode
}

// 彈跳視窗尺寸
export const dialogResize = computed(() => {
  if (width.value <= 768) {
    return '85%'
  } else if (width.value <= 1024) {
    return '50%'
  } else {
    return '30%'
  }
})

// 蒐藏type轉換
export const favoriteTypeDisplay = (favorite: IFavorite | IViewlog) => {
  if (favorite.type === 'App\\Models\\Project') {
    return 'PROJECT'
  } else if (favorite.type === 'App\\Models\\Selection') {
    return 'IW SELECT'
  } else if (favorite.type === 'App\\Models\\Report') {
    return 'NEWS'
  } else if (favorite.type === 'App\\Models\\Opinion') {
    return 'OPINION'
  } else if (favorite.type === 'App\\Models\\Story') {
    return 'STORY'
  } else if (favorite.type === 'App\\Models\\Issue') {
    return 'ISSUE'
  } else if (favorite.type === 'App\\Models\\Contest') {
    return 'CONTEST'
  }
}

export const favoriteTypeDisplaySearch = (item: ISearchItem) => {
  if (item.model_type === 'App\\Models\\Project') {
    return 'PROJECT'
  } else if (item.model_type === 'App\\Models\\Selection') {
    return 'IW SELECT'
  } else if (item.model_type === 'App\\Models\\Report') {
    return 'NEWS'
  } else if (item.model_type === 'App\\Models\\Opinion') {
    return 'OPINION'
  } else if (item.model_type === 'App\\Models\\Story') {
    return 'STORY'
  } else if (item.model_type === 'App\\Models\\Issue') {
    return 'ISSUE'
  } else if (item.model_type === 'App\\Models\\Contest') {
    return 'CONTEST'
  }
}
// 蒐藏url type轉換
export const favoriteToUrlType = (favorite: IFavorite | IViewlog) => {
  if (favorite.type === 'App\\Models\\Project') {
    return '/' + favorite.cat.toLocaleLowerCase()
  } else if (favorite.type === 'App\\Models\\Story') {
    return '/' + favorite.cat.toLocaleLowerCase() + '-stories'
  } else if (favorite.type === 'App\\Models\\Issue') {
    if (favorite.cat.toLocaleLowerCase() === 'detail') return '/issue-detail'
    if (favorite.cat.toLocaleLowerCase() === 'iw') return '/issue'
  } else if (favorite.type === 'App\\Models\\Selection') {
    return '/iwselect'
  } else if (favorite.type === 'App\\Models\\Report') {
    return '/news'
  } else if (favorite.type === 'App\\Models\\Opinion') {
    return '/opinion'
  } else if (favorite.type === 'App\\Models\\Contest') {
    return '/competition'
  }

  return ''
}

export const favoriteToUrlTypeSearch = (item: ISearchItem) => {
  if (item.model_type === 'App\\Models\\Project') {
    return '/' + item.cat.toLocaleLowerCase()
  } else if (item.model_type === 'App\\Models\\Story') {
    return '/' + item.cat.toLocaleLowerCase() + '-stories'
  } else if (item.model_type === 'App\\Models\\Issue') {
    if (item.cat.toLocaleLowerCase() === 'detail') return '/issue-detail'
    if (item.cat.toLocaleLowerCase() === 'iw') return '/issue'
  } else if (item.model_type === 'App\\Models\\Selection') {
    return '/iwselect'
  } else if (item.model_type === 'App\\Models\\Report') {
    return '/news'
  } else if (item.model_type === 'App\\Models\\Opinion') {
    return '/opinion'
  } else if (item.model_type === 'App\\Models\\Contest') {
    return '/competition'
  }

  return ''
}
