import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IFilterParams } from './index'
import type { ICategory, IYearCategory } from './types/category'
import type { ILinks, IMeta, ISeo } from './types/response'
import type { ITag } from './types/tag'
import type { IBrand, IOffice } from './types/office'
import type { IContentBlock } from './types/block'
import { IContentBlock } from './types/block'
import { ITag } from './types/tag'
import { ISeo } from './types/response'

export interface IIssueFilterParams extends IFilterParams {
  category_id: string
  year: string
  tags: string[]
  type: string
}

export interface IIssue {
  id: number
  type: string
  title: string
  subtitle: string
  category: string
  release_date: string
  description: string
  content: IContentBlock[]
  price: number
  member_price: number
  sell_out: boolean
  image: string
  images: string[]
  tags: ITag[]
  seo: ISeo
  status: number
}

export interface IIssueList {
  data: IIssue[]
  meta: IMeta
  links: ILinks
}

export interface IIssueIndexResultModel {
  top: IIssue[]
  list: IIssueList
}

export interface IIssueResultModel {
  info: IIssue
  recommends: IIssue[]
}

enum Api {
  issues = '/issues',
  issue = '/issues/:id'
}

export function getIssues(params?: IIssueFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IIssueIndexResultModel>(Api.issues, params, option)
}

export function getIssue(id: string, option?: HttpOption<any>) {
  return useHttp.get<IIssueResultModel>(Api.issue.replace(':id', id), option)
}
