import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IFilterParams } from './index'
import type { ICategory, IYearCategory } from './types/category'
import type { ILinks, IMeta, ISeo } from './types/response'
import type { ITag } from './types/tag'
import type { IBrand, IOffice } from './types/office'

export interface IAdBlock {
  type: string
  image: string
  en_title: string
  title: string
  text: string
  url: string
}

export interface IDefaultPageInfo {
  banners: [
    {
      url?: string
      image: string
      title?: string
      en_title?: string
    }
  ]
  ad_first: IAdBlock[]
  seo: ISeo
}

export interface IHomeFirstBlock {
  image: string
  title: string
  en_title: string
  url: string
}

export interface IHomeReport {
  id: number
  title: string
  en_title: string
  image: string
  seo: ISeo
}

export interface IHomeStory {
  id: number
  type: string
  title: string
  en_title: string
  image: string
  seo: ISeo
}

export interface IHomeOpinion {
  id: number
  title: string
  en_title: string
  subtitle: string
  description: string
  en_description: string
  image: string
  seo: ISeo
}

export interface IHomeIssue {
  id: number
  type: string
  title: string
  en_title: string
  price: number
  member_price: number
  sell_out: boolean
  image: string
  seo: ISeo
}

export interface IHomeContest {
  url: string
  image: string
}

export interface IHomePopup {
  image: string
  title: string
  en_title: string
}

export interface IHomeExhibition {
  url: string
  image: string
}

export interface IHomePageInfo extends IDefaultPageInfo {
  first_block: IHomeFirstBlock[]
  reports: IHomeReport[]
  stories: IHomeStory[]
  opinions: IHomeOpinion[]
  issues: IHomeIssue[]
  exhibition: IHomeExhibition
  ad_second: IAdBlock
  ad_block: IAdBlock[]
  reports_is_visible: boolean
  stories_is_visible: boolean
  opinions_is_visible: boolean
  exhibition_is_visible: boolean
  issues_is_visible: boolean
  popup_is_visible: boolean
  contest_is_visible: boolean
  contest: IHomeContest[]
  popup: IHomePopup
  seo: ISeo
}

enum Api {
  page = '/page/:key'
}

export function getPageInfo(key: string, option?: HttpOption<any>) {
  return useHttp.get<IDefaultPageInfo>(Api.page.replace(':key', key), option)
}

export function getHomePageInfo(option?: HttpOption<any>) {
  return useHttp.get<IHomePageInfo>(Api.page.replace(':key', 'home'), option)
}
