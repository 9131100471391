import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IFilterParams } from './index'
import type { ICategory, IYearCategory } from './types/category'
import type { ILinks, IMeta, ISeo } from './types/response'
import type { ITag } from './types/tag'
import type { IBrand, IOffice } from './types/office'
import type { IContentBlock } from './types/block'

export interface IOpinionFilterParams extends IFilterParams {
  category_id: string
}

export interface IOpinion {
  id: number
  title: string
  en_title: string
  subtitle: string
  categories: ICategory[]
  issue_name: string
  release_date: string
  description: string
  en_description: string
  notes: string
  content: IContentBlock[]
  brand: IBrand
  image: string
  tags: ITag[]
  seo: ISeo
  status: number
}

export interface IOpinionList {
  data: IOpinion[]
  meta: IMeta
  links: ILinks
}

export interface IOpinionIndexResultModel {
  list: IOpinionList
  categories: ICategory[]
  top_tags: ITag[]
}

export interface IOpinionResultModel {
  info: IOpinion
  recommends: IOpinion[]
}

enum Api {
  opinions = '/opinions',
  opinion = '/opinions/:id'
}

export function getOpinions(params?: IOpinionFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IOpinionIndexResultModel>(Api.opinions, params, option)
}

export function getOpinion(id: string, option?: HttpOption<any>) {
  return useHttp.get<IOpinionResultModel>(Api.opinion.replace(':id', id), option)
}
